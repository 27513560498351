// components/regulamin/Regulamin.js

import React from "react";
import "./Regulamin.scss";

const Regulamin = () => {
  return (
    <div className="regulamin">
      <h1>Regulamin Sklepu Internetowego</h1>

      <section>
        <h2>1. Postanowienia Ogólne</h2>
        <p>
          Niniejszy regulamin określa zasady korzystania ze sklepu internetowego{" "}
          <strong>KittyShop</strong>, prowadzonego przez{" "}
          <strong>KittyShop</strong>
        </p>
      </section>

      <section>
        <h2>2. Warunki Zakupu</h2>
        <p>
          Klientem może być każda osoba fizyczna posiadająca pełną zdolność do
          czynności prawnych. Zakupu można dokonać poprzez dodanie produktów do
          koszyka i wypełnienie formularza zamówienia.
        </p>
      </section>

      <section>
        <h2>3. Płatności</h2>
        <p>Sklep akceptuje następujące formy płatności:</p>
        <ul>
          <li>Płatność online - karta kredytowa, przelew elektroniczny</li>
          <li>Przelew bankowy</li>
          <li>Płatność Blik</li>
        </ul>
        <p>Realizacja zamówienia rozpoczyna się po zaksięgowaniu wpłaty.</p>
      </section>

      <section>
        <h2>4. Dostawa</h2>
        <p>
          Zamówienia dostarczane są za pośrednictwem firm kurierskich na terenie{" "}
          <strong>Polski</strong>. Koszty dostawy są zależne od wagi zamówienia
          oraz wybranego sposobu wysyłki i są widoczne podczas składania
          zamówienia.
        </p>
      </section>

      <section>
        <h2>5. Zwroty i Prawo do Odstąpienia</h2>
        <p>
          Klient ma prawo odstąpić od umowy bez podania przyczyny w ciągu 14 dni
          od dnia otrzymania towaru. W celu skorzystania z tego prawa należy
          przesłać pisemne oświadczenie o odstąpieniu oraz zwrócić produkt w
          orginalnym oraz nienaruszonym opakowaniu nienaruszonym.
        </p>
      </section>

      <section>
        <h2>6. Reklamacje</h2>
        <p>
          W przypadku stwierdzenia wadliwości produktu, klient ma prawo do
          złożenia reklamacji. Reklamacje można składać pisemnie lub drogą
          elektroniczną na adres <strong>support@kittyshop.pl</strong>. Sklep
          rozpatrzy reklamację w ciągu 14 dni roboczych od jej otrzymania.
        </p>
      </section>

      <section>
        <h2>7. Ochrona Danych Osobowych</h2>
        <p>
          Dane osobowe klientów są przetwarzane zgodnie z obowiązującymi
          przepisami prawa oraz zgodnie z Polityką Prywatności, dostępną na
          stronie sklepu.
        </p>
      </section>

      <section>
        <h2>8. Postanowienia Końcowe</h2>
        <p>
          Sklep zastrzega sobie prawo do zmiany regulaminu. Wszelkie zmiany będą
          publikowane na stronie sklepu. Korzystanie ze sklepu po wprowadzeniu
          zmian oznacza ich akceptację.
        </p>
      </section>

      <section>
        <h2>9. Kontakt</h2>
        <p>
          W razie jakichkolwiek pytań dotyczących regulaminu lub działania
          sklepu prosimy o kontakt pod adresem e-mail:{" "}
          <strong>support@kittyshop.pl</strong>
        </p>
      </section>
    </div>
  );
};

export default Regulamin;
