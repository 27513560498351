import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.iconContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="green"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={styles.icon}
        >
          <path d="M20 6L9 17l-5-5" />
        </svg>
      </div>
      <h1 style={styles.title}>Płatność zakończona sukcesem!</h1>
      <p style={styles.message}>
        Dziękujemy za dokonanie płatności. Twoje zamówienie zostało pomyślnie
        zrealizowane.
      </p>
      <button style={styles.button} onClick={() => navigate("/")}>
        Wróć na stronę główną
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    padding: "20px",
  },
  iconContainer: {
    backgroundColor: "#eaffea",
    borderRadius: "50%",
    padding: "20px",
    marginBottom: "20px",
  },
  icon: {
    width: "50px",
    height: "50px",
  },
  title: {
    fontSize: "24px",
    color: "#333",
    marginBottom: "10px",
  },
  message: {
    fontSize: "16px",
    color: "#666",
    marginBottom: "20px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "white",
    backgroundColor: "#4CAF50",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none",
  },
};

export default PaymentSuccess;
