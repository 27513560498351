import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./products.scss";

const Products = ({ products, categories, addToCart }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertProductName, setAlertProductName] = useState("");
  const navigate = useNavigate();

  const chooseCategory = (category) => {
    setSelectedCategory(category);
    console.log("Selected Category:", category);
  };

  const goToProductDetails = (productId) => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Dodaj przewijanie na górę
    navigate(`/product/${productId}`);
  };

  const handleAddToCart = (product) => {
    addToCart(product);
    setAlertProductName(product.name);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  return (
    <div className="app-products-container container">
      {/* Alert */}
      {showAlert && (
        <div className="add-to-cart-alert">
          {alertProductName} dodano do koszyka!
        </div>
      )}

      <div className="categories">
        <div
          className={`btn category-btn ${
            selectedCategory === "" ? "active" : ""
          }`}
          onClick={() => chooseCategory("")}
        >
          All
        </div>
        {categories &&
          categories.map((category, index) => (
            <div
              key={index}
              className={`btn category-btn ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => chooseCategory(category)}
            >
              {category}
            </div>
          ))}
      </div>

      <div className="products-grid">
        {products
          .filter((product) =>
            selectedCategory ? product.category === selectedCategory : true
          )
          .map((product) => (
            <div
              key={product.id}
              className="product"
              onClick={() => goToProductDetails(product.id)}
            >
              <div
                className="img"
                style={{
                  backgroundImage: `url(${product.img})`,
                  backgroundSize: "cover",
                }}
              ></div>
              <h4>{product.name}</h4>
              <p className="description" title={product.description}>
                {product.description}
              </p>

              <div className="price-container">
                {product.discountPrice ? (
                  <>
                    <span className="price original-price">
                      {product.originalPrice} PLN
                    </span>
                    <span className="price discount-price">
                      {product.discountPrice} PLN
                    </span>
                  </>
                ) : (
                  <span className="price">{product.originalPrice} PLN</span>
                )}
              </div>

              <button
                className="btn buy-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToCart(product);
                }}
              >
                DODAJ DO KOSZYKA
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Products;
