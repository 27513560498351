import React from "react";
import "./Zwroty.scss";

const Zwroty = () => {
  return (
    <div className="zwroty">
      <h1>Zwroty i Wymiany</h1>

      <section>
        <h2>1. Prawo do odstąpienia od umowy</h2>
        <p>
          Klient ma prawo odstąpić od umowy bez podania przyczyny w ciągu 14 dni
          od dnia otrzymania towaru. Aby odstąpić od umowy, należy złożyć
          oświadczenie o odstąpieniu oraz odesłać wszystko co znajdowało się w
          paczce w oryginalnym i nienaruszonym opakowaniu.
        </p>
      </section>

      <section>
        <h2>2. Procedura zwrotu</h2>
        <p>
          Aby zwrócić produkt, należy skontaktować się z naszym działem obsługi
          klienta na adres e-mail: <strong>support@kittyshop.pl</strong>. Po
          otrzymaniu zgłoszenia przekażemy szczegóły dotyczące zwrotu.
        </p>
      </section>

      <section>
        <h2>3. Koszty zwrotu</h2>
        <p>
          Klient ponosi koszty przesyłki zwrotnej, chyba że zwrot wynika z
          błędnej realizacji zamówienia przez sklep. W takim przypadku koszty
          zwrotu zostaną pokryte przez sklep.
        </p>
      </section>

      <section>
        <h2>4. Zwroty płatności</h2>
        <p>
          Zwrot płatności zostanie dokonany na numer rachunku podany przez
          klienta lub metodą, której użył przy składaniu zamówienia, w ciągu 14
          dni od otrzymania zwróconego towaru.
        </p>
      </section>

      <section>
        <h2>5. Reklamacje</h2>
        <p>
          W przypadku otrzymania wadliwego towaru klient ma prawo do złożenia
          reklamacji. W celu złożenia reklamacji prosimy o kontakt mailowy na
          adres: <strong>support@kittyshop.pl</strong>.
        </p>
      </section>
    </div>
  );
};

export default Zwroty;
