// components/privacyPolicy/PrivacyPolicy.js

import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Polityka Prywatności</h1>
      <section>
        <h2>1. Wstęp</h2>
        <p>
          Niniejsza Polityka Prywatności określa zasady przetwarzania danych
          osobowych zbieranych za pośrednictwem sklepu internetowego{" "}
          <strong>KittyShop</strong>. Administratorem danych osobowych jest{" "}
          <strong>KittyShop</strong>,. Wszelkie operacje na danych osobowych są
          zgodne z obowiązującymi przepisami, w tym z RODO.
        </p>
      </section>

      <section>
        <h2>2. Zakres Danych Osobowych</h2>
        <p>
          W ramach prowadzenia sklepu internetowego możemy zbierać następujące
          dane osobowe:
        </p>
        <ul>
          <li>Imię i nazwisko</li>
          <li>Adres e-mail, numer telefonu</li>
          <li>Adres dostawy</li>
          <li>Informacje związane z płatnościami</li>
          <li>
            Dane zbierane automatycznie, takie jak adres IP, pliki cookies
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Cele Przetwarzania Danych</h2>
        <p>Gromadzone dane są wykorzystywane w następujących celach:</p>
        <ul>
          <li>Realizacja zamówień i dostarczanie produktów</li>
          <li>Obsługa zapytań i kontaktów z klientem</li>
          <li>Wysyłka materiałów marketingowych (za zgodą)</li>
          <li>Personalizacja oferty i analizy statystyczne</li>
          <li>Spełnianie wymogów prawnych</li>
        </ul>
      </section>

      <section>
        <h2>4. Przekazywanie Danych Osobowych</h2>
        <p>
          Dane osobowe mogą być udostępniane zaufanym partnerom w zakresie
          niezbędnym do realizacji usług, w szczególności:
        </p>
        <ul>
          <li>Firmom kurierskim i logistycznym</li>
          <li>Procesorom płatności</li>
          <li>Podmiotom świadczącym usługi IT i wsparcie techniczne</li>
        </ul>
        <p>
          Przekazywanie danych odbywa się zgodnie z obowiązującymi przepisami, a
          partnerzy zobowiązani są do ochrony danych osobowych.
        </p>
      </section>

      <section>
        <h2>5. Prawa Użytkownika</h2>
        <p>Każdy użytkownik ma prawo do:</p>
        <ul>
          <li>Dostępu do swoich danych osobowych</li>
          <li>Poprawiania błędnych lub niekompletnych danych</li>
          <li>Usunięcia danych osobowych w uzasadnionych przypadkach</li>
          <li>Ograniczenia przetwarzania danych</li>
          <li>Zgłoszenia sprzeciwu wobec przetwarzania</li>
        </ul>
        <p>
          W celu skorzystania z powyższych praw, prosimy o kontakt na adres
          <strong>support@kittyshop.pl</strong>.
        </p>
      </section>

      <section>
        <h2>6. Pliki Cookies</h2>
        <p>
          Nasz sklep wykorzystuje pliki cookies w celu poprawy doświadczeń
          użytkownika oraz personalizacji treści. Pliki cookies mogą być
          stosowane m.in. do:
        </p>
        <ul>
          <li>Zapamiętywania preferencji użytkownika</li>
          <li>Śledzenia aktywności użytkownika na stronie</li>
          <li>Analizowania ruchu na stronie</li>
        </ul>
        <p>
          Użytkownik może zarządzać plikami cookies za pomocą ustawień
          przeglądarki.
        </p>
      </section>

      <section>
        <h2>7. Zmiany w Polityce Prywatności</h2>
        <p>
          Polityka Prywatności może ulegać zmianom. Aktualizacje będą
          publikowane na tej stronie, a każda zmiana zacznie obowiązywać od
          momentu jej opublikowania.
        </p>
      </section>

      <section>
        <h2>8. Kontakt</h2>
        <p>
          W przypadku pytań dotyczących Polityki Prywatności prosimy o kontakt
          na adres e-mail: <strong>support@kittyshop.pl</strong>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
