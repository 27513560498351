import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./ProductDetails.scss";

const ProductDetails = ({ products, addToCart }) => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const [showModal, setShowModal] = useState(false);

  const product = products?.find((item) => item.id === Number(productId));

  useEffect(() => {
    if (product) {
      document.title = product.name;
      window.scrollTo({ top: 0, behavior: "smooth" }); // Dodaj przewijanie na górę
    } else {
      document.title = "KittyShop";
    }
    return () => {
      document.title = "KittyShop";
    };
  }, [product]);

  if (!products || products.length === 0) {
    return <p>Ładowanie produktu...</p>;
  }

  if (!product) {
    return <p>Produkt nie znaleziony.</p>;
  }

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleBuyNow = () => {
    if (!selectedSize && product.sizeAvailable) {
      alert("Wybierz rozmiar przed zakupem!");
      return;
    }

    const productToBuy = {
      ...product,
      price: product.discountPrice || product.originalPrice,
      quantity,
      selectedSize,
      totalPrice: (product.discountPrice || product.originalPrice) * quantity,
    };

    navigate("/checkout", { state: { localCart: [productToBuy] } });
  };

  const handleAddToCart = () => {
    if (!selectedSize && product.sizeAvailable) {
      alert("Wybierz rozmiar przed dodaniem do koszyka!");
      return;
    }

    const productToAdd = {
      ...product,
      quantity,
      selectedSize,
      totalPrice: (product.discountPrice || product.originalPrice) * quantity,
    };

    addToCart(productToAdd);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2000);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="product-details-container">
      {showAlert && <div className="add-to-cart-alert">Dodano do koszyka!</div>}

      <div
        className="product-details-image"
        style={{ backgroundImage: `url(${product.img})` }}
        onClick={openModal}
      ></div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <img src={product.img} alt={product.name} />
            <button className="close-modal" onClick={closeModal}>
              Zamknij
            </button>
          </div>
        </div>
      )}

      <div className="product-details-info">
        <h2 className="product-details-title">{product.name}</h2>
        <p className="product-details-price">
          {product.discountPrice ? (
            <>
              <span className="product-original-price">
                {product.originalPrice} zł
              </span>
              <span className="product-discount-price">
                {product.discountPrice} zł
              </span>
              <span className="product-discount-label">
                -{product.discount}% PRZECENY
              </span>
            </>
          ) : (
            <span>{product.originalPrice} zł</span>
          )}
        </p>
        <div className="product-details-options">
          <p>
            NAJNIŻSZA CENA Z OSTATNICH 30DNI{" "}
            {product.discountPrice || product.originalPrice} zł
          </p>
          <p>WYSYŁKA OD 2-14 DNI ROBOCZYCH</p>
          <p>14 DNI NA ZWROT</p>
        </div>

        <div className="product-quantity">
          <button onClick={decreaseQuantity}>-</button>
          <span>{quantity}</span>
          <button onClick={increaseQuantity}>+</button>
        </div>

        {product.sizeAvailable && (
          <div className="product-size-selector">
            <label htmlFor="size">Wybierz rozmiar:</label>
            <select id="size" value={selectedSize} onChange={handleSizeChange}>
              <option value="">Wybierz rozmiar</option>
              {product.availableSizes?.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="product-actions">
          <button className="add-to-cart-btn" onClick={handleAddToCart}>
            DODAJ DO KOSZYKA
          </button>
          <button className="buy-now-btn" onClick={handleBuyNow}>
            KUP TERAZ
          </button>
        </div>

        <strong>Opis:</strong>
        <div className="product-details-description">
          {product.longDescription}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
