import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Checkout.scss";

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { localCart = [] } = location.state || {};

  const [cart, setCart] = useState(localCart);
  const [deliveryDetails, setDeliveryDetails] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    postalCode: "",
    phone: "",
    email: "",
  });

  const [deliveryMethod, setDeliveryMethod] = useState(""); // Stan dla wybranej metody dostawy

  // Mapowanie polskich etykiet na angielskie klucze
  const fieldLabels = {
    firstName: "Imię",
    lastName: "Nazwisko",
    address: "Adres",
    city: "Miasto",
    postalCode: "Kod pocztowy",
    phone: "Telefon",
    email: "E-mail",
  };

  // Funkcja do zmiany ilości produktów
  const updateProductQuantity = (id, delta) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.id === id
            ? {
                ...item,
                quantity: item.quantity + delta,
                totalPrice: (item.quantity + delta) * item.price,
              }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  // Dodawanie kosztu dostawy do koszyka
  const addDeliveryToCart = (method, cost) => {
    const deliveryItem = {
      id: "delivery",
      name: `Dostawa: ${method}`,
      price: cost,
      quantity: 1,
      totalPrice: cost,
    };

    setCart((prevCart) => {
      const existingDeliveryItem = prevCart.find(
        (item) => item.id === "delivery"
      );
      if (existingDeliveryItem) {
        // Usuń poprzednią dostawę i dodaj nową
        return [
          ...prevCart.filter((item) => item.id !== "delivery"),
          deliveryItem,
        ];
      } else {
        // Dodaj nową dostawę
        return [...prevCart, deliveryItem];
      }
    });
  };

  // Funkcja obsługi zmian w formularzu dostawy
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDeliveryDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Funkcja obsługi wyboru metody dostawy
  const handleDeliveryChange = (event) => {
    const { value } = event.target;
    setDeliveryMethod(value);
    if (value === "pocztaPolska") {
      addDeliveryToCart("Poczta Polska", 12.99);
    } else if (value === "kurier") {
      addDeliveryToCart("Kurier inpost", 14.99);
    }
  };

  // Obliczanie całkowitej ceny koszyka
  const calculateTotalPrice = () =>
    cart.reduce((acc, item) => acc + item.totalPrice, 0).toFixed(2);

  // Walidacja formularza
  const validateForm = () => {
    // Sprawdzanie, czy wszystkie dane zostały wypełnione
    for (const [key, label] of Object.entries(fieldLabels)) {
      if (!deliveryDetails[key]) {
        alert(`Pole "${label}" jest wymagane.`);
        return false;
      }
    }
    // Sprawdzanie, czy metoda dostawy została wybrana
    if (!deliveryMethod) {
      alert("Proszę wybrać metodę dostawy.");
      return false;
    }
    return true;
  };

  // Funkcja obsługi Checkout
  const handleCheckout = async () => {
    if (!validateForm()) return;

    try {
      const response = await fetch("https://kittyshop.pl:5000/xd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartItems: cart,
          deliveryDetails,
        }),
      });

      if (!response.ok)
        throw new Error(`Checkout session error: ${response.statusText}`);

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      } else {
        throw new Error("Nie otrzymano adresu URL do płatności.");
      }
    } catch (error) {
      console.error("Checkout error:", error);
      alert(
        "Wystąpił błąd podczas procesu składania zamówienia. Spróbuj ponownie. Lub skontaktuj się z obsługą klienta."
      );
    }
  };

  return (
    <div className="checkout">
      <header className="checkout-header">
        <h1>Podsumowanie zamówienia</h1>
        <p>Dokończ swoje zamówienie poniżej</p>
      </header>

      <section className="cart-section">
        <h2>Twój koszyk</h2>
        {cart.length === 0 ? (
          <p>Twój koszyk jest pusty</p>
        ) : (
          <ul className="cart-list">
            {cart.map((product) => (
              <li key={product.id} className="cart-item">
                <div className="item-details">
                  <h3>{product.name}</h3>
                  <p>Cena: {product.price.toFixed(2)} PLN</p>
                  {product.selectedSize && (
                    <p>Rozmiar: {product.selectedSize}</p>
                  )}
                </div>
                <div className="item-actions">
                  {product.id !== "delivery" && (
                    <>
                      <button
                        onClick={() => updateProductQuantity(product.id, -1)}
                      >
                        -
                      </button>
                      <span>{product.quantity}</span>
                      <button
                        onClick={() => updateProductQuantity(product.id, 1)}
                      >
                        +
                      </button>
                    </>
                  )}
                </div>
                <p className="item-total">
                  Razem: {product.totalPrice.toFixed(2)} PLN
                </p>
              </li>
            ))}
          </ul>
        )}
        <p>
          <strong>Cena całkowita: {calculateTotalPrice()} PLN</strong>
        </p>
      </section>

      <section className="delivery-section">
        <h3>Wybierz metodę dostawy</h3>
        <div className="delivery-options">
          <label>
            <input
              type="radio"
              name="delivery"
              value="pocztaPolska"
              checked={deliveryMethod === "pocztaPolska"}
              onChange={handleDeliveryChange}
            />
            Poczta Polska (12,99 PLN)
          </label>
          <label>
            <input
              type="radio"
              name="delivery"
              value="kurier"
              checked={deliveryMethod === "kurier"}
              onChange={handleDeliveryChange}
            />
            Kurier inpost (14,99 PLN)
          </label>
        </div>
      </section>

      <section className="summary-section">
        <h2>Podsumowanie zamówienia</h2>
        <h3>Dane do dostawy</h3>
        <form className="delivery-form">
          {Object.entries(fieldLabels).map(([key, label]) => (
            <div key={key} className="form-group">
              <label>
                {label}:
                <input
                  type="text"
                  name={key}
                  value={deliveryDetails[key]}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
          ))}
        </form>

        <div className="checkout-actions">
          <button onClick={() => navigate("/")} className="btn btn-secondary">
            Wróć do sklepu
          </button>
          <button onClick={handleCheckout} className="btn btn-primary">
            Przejdź do płatności
          </button>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
