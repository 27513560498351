import React, { useEffect, useState } from "react";
import { InpostGeowidget } from "react-inpost-geowidget";
import { Header, Products, Footer } from "./components";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductDetails from "./components/productDetails/ProductDetails";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Regulamin from "./components/Regulamin/Regulamin";
import Zwroty from "./components/Zwroty/Zwroty";
import Checkout from "./components/Checkout/Checkout";
import NotFound from "./components/rest/404";
import PaymentSuccess from "./components/confirmation/confiramtion";
import "./App.scss";

// Komponent główny
function App() {
  const [products, setProducts] = useState([]); // Lista produktów
  const [cart, setCart] = useState([]); // Koszyk użytkownika
  const [categories, setCategories] = useState([]); // Kategorie produktów

  // Dodawanie produktu do koszyka
  const addToCart = (product) => {
    const existingProductIndex = cart.findIndex(
      (item) =>
        item.id === product.id && item.selectedSize === product.selectedSize
    );

    const quantity = product.quantity || 1; // Ustaw domyślną ilość na 1
    const price = product.price || 0; // Ustaw domyślną cenę na 0

    if (existingProductIndex !== -1) {
      // Update quantity if product already exists in the cart
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += quantity;
      updatedCart[existingProductIndex].totalPrice += price * quantity;

      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); // Save updated cart to localStorage
    } else {
      // Add new product to the cart
      const newCart = [
        ...cart,
        {
          ...product,
          quantity: quantity, // Dodaj domyślną ilość
          totalPrice: price * quantity, // Oblicz totalPrice
        },
      ];
      setCart(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart)); // Save new cart to localStorage
    }
  };

  // Czyszczenie koszyka
  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart"); // Czyszczenie localStorage
  };

  // Pobieranie produktów z API i ustawianie kategorii
  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get("/products.json");
        const data = response.data;
        setProducts(data);
        getCategories(data);
      } catch (error) {
        console.error("Błąd przy pobieraniu produktów:", error);
      }
    };

    // Wydzielanie unikalnych kategorii z listy produktów
    const getCategories = (products) => {
      const uniqueCategories = Array.from(
        new Set(products.map((product) => product.category))
      );
      setCategories(uniqueCategories);
    };

    getProducts();
  }, []);

  // Wczytanie koszyka z localStorage przy załadowaniu aplikacji
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    if (storedCart) {
      setCart(storedCart);
    }
  }, []);

  // Callback do obsługi wyboru paczkomatu
  const onPointCallback = (locker) => {
    console.log("Wybrano paczkomat:", locker);
    // Możesz tutaj dodać zapis paczkomatu do backendu lub stanu aplikacji
  };

  return (
    <Router>
      <div className="App">
        {/* Nagłówek z informacjami o koszyku */}
        <Header cart={cart} clearCart={clearCart} />

        {/* Konfiguracja tras */}
        <Routes>
          <Route
            path="/"
            element={
              <Products
                products={products}
                categories={categories}
                addToCart={addToCart}
              />
            }
          />
          <Route
            path="/product/:productId"
            element={
              <ProductDetails products={products} addToCart={addToCart} />
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/regulamin" element={<Regulamin />} />
          <Route path="/zwroty" element={<Zwroty />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          {/* Ścieżka do strony z checkout i paczkomatami */}
          <Route
            path="/checkout"
            element={
              <div>
                <Checkout cart={cart} />
              </div>
            }
          />
        </Routes>

        {/* Stopka */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
