import image404 from "./404-erroe.png";
import { useNavigate } from "react-router-dom";
import "./404.scss";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="er">
      <h1>404 - Nie znaleziono strony</h1>
      <img src={image404} alt="404" className="a" />
      <button onClick={() => navigate("/")}>Wróć do strony głównej</button>
    </div>
  );
};

export default NotFound;
