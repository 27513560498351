import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./header.scss";
import CartIcon from "./cart.icon";
import logo from "./logo.png";
import napis from "./Napis.png";

const Header = ({ cart, clearCart }) => {
  const [cartState, setCartState] = useState(false);
  const [localCart, setLocalCart] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const cartClick = () => setCartState((prev) => !prev);

  // Calculate total price of the cart
  const getTotalPrice = useMemo(() => {
    if (!localCart.length) return "0.00";
    const total = localCart.reduce(
      (acc, product) => acc + product.totalPrice,
      0
    );
    return total.toFixed(2); // Format to 2 decimal places
  }, [localCart]);

  const resetCartButton = () => {
    clearCart();
    setLocalCart([]);
  };

  // Setup the local cart (aggregate items and handle size)
  const setupCart = useCallback(() => {
    const itemMap = new Map();
    cart.forEach((product) => {
      const {
        id,
        name,
        discountPrice,
        originalPrice,
        thumbnail,
        quantity,
        selectedSize,
      } = product;
      const priceToUse = discountPrice || originalPrice;
      const key = `${id}-${selectedSize || ""}`;

      if (itemMap.has(key)) {
        const existingItem = itemMap.get(key);
        existingItem.quantity += quantity;
        existingItem.totalPrice = existingItem.price * existingItem.quantity; // Update totalPrice
      } else {
        itemMap.set(key, {
          id,
          name,
          price: priceToUse,
          totalPrice: priceToUse * quantity, // Initial totalPrice
          quantity,
          selectedSize, // Store the selected size
          description: product.description || "Brak opisu produktu",
          img: thumbnail || "https://example.com/default-thumbnail.jpg",
        });
      }
    });
    setLocalCart(Array.from(itemMap.values()));
  }, [cart]);

  useEffect(() => {
    setupCart();
  }, [setupCart]);

  // Handle checkout

  // Navigate to checkout summary
  const goToCheckout = () =>
    navigate("/checkout", {
      state: { localCart, getTotalPrice },
    });

  // Calculate total quantity of products in cart
  const quantity = localCart.reduce((acc, item) => acc + item.quantity, 0);

  // Close the cart when the route changes
  useEffect(() => {
    setCartState(false); // Close cart on route change
  }, [location]);

  return (
    <div className="app-header-container">
      {/* Sliding Info Bar */}
      <div class="info-bar">
        <div class="sliding-text">
          NAJLEPSZE CENY · AUTENTYCZNOŚĆ PRODUKTÓW · ZWROTY DO 14 DNI · ZNIŻKA
          NA DOSTWE DO 06.12 · ZWROTY DO 14 DNI · AUTENTYCZNOŚĆ PRODUKTÓW ·
          NAJLEPSZE CENY
        </div>
      </div>

      <header>
        <div className="header container">
          <a href="/">
            <img src={logo} className="logo" alt="Logo" />
          </a>
          <a href="/">
            <img src={napis} alt="Napis" />
          </a>

          <div onClick={cartClick}>
            <div className="cart-quantity">{quantity}</div>
            <CartIcon width={40} />
          </div>
        </div>
      </header>

      {cartState && (
        <div className="cart animate__animated animate__fadeIn">
          <h2>Koszyk</h2>
          {localCart.length === 0 ? (
            <p>Twój koszyk jest pusty.</p>
          ) : (
            localCart.map((product) => (
              <div key={product.id} className="cart-item">
                <div className="product-details">
                  <p className="product-name">{product.name}</p>
                  {product.selectedSize && (
                    <p className="product-size">
                      Rozmiar: {product.selectedSize}
                    </p>
                  )}
                  <div className="quantity-control">
                    <span className="product-quantity">
                      Ilość: {product.quantity}
                    </span>
                    <span className="product-quantity">
                      Cena za sztukę: {product.price} PLN
                    </span>
                  </div>
                </div>
                <p className="product-total-price">
                  Razem: {product.totalPrice.toFixed(2)} PLN
                </p>
              </div>
            ))
          )}
          <p className="total-price">
            Razem: <br /> {getTotalPrice} PLN
          </p>
          <div className="discount-code-container">
            <button onClick={resetCartButton}>Wyczyść koszyk</button>
          </div>

          <button onClick={goToCheckout} className="submit-button">
            Przejdź do podsumowania
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
