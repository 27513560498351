import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./footer.scss";
import {
  FaInstagram,
  FaTiktok,
  FaGooglePay,
  FaApplePay,
  FaCcVisa,
  FaCcMastercard,
} from "react-icons/fa";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const goToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  const goToZwroty = () => {
    navigate("/zwroty");
  };

  const goToRegulamin = () => {
    navigate("/regulamin");
  };

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setStatus("Proszę podać adres e-mail");
      return;
    }

    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setStatus("Dziękujemy za zapisanie się!");
        setEmail("");
      } else {
        setStatus("Wystąpił błąd. Spróbuj ponownie.");
      }
    } catch (error) {
      setStatus("Błąd połączenia. Spróbuj ponownie później.");
    }
  };

  return (
    <div className="app-footer-container">
      <div className="footer-content">
        {/* Left Section: Logo and Links */}
        <div className="footer-left">
          <div className="footer-logo">
            {/* <img src="./logo.png" alt="KittyShop Logo" /> */}
          </div>
          <div className="footer-links">
            <a onClick={goToPrivacyPolicy}>Polityka Prywatności</a>
            <a onClick={goToRegulamin}>Regulamin</a>
            <a onClick={goToZwroty}>Zwrot / Wymiana / Reklamacje</a>
          </div>
        </div>

        {/* Center Section: Newsletter */}
        <div className="footer-center">
          <p className="newsletter-title">Newsletter</p>
          <form className="newsletter-signup" onSubmit={handleNewsletterSubmit}>
            <input
              type="email"
              placeholder="Email"
              aria-label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Zapisz się</button>
          </form>
          {status && <p className="status-message">{status}</p>}
        </div>

        {/* Right Section: Contact Information */}
        <div className="footer-right">
          <p>
            Email:{" "}
            <a href="mailto:support@kittyshop.pl">support@kittyshop.pl</a>
          </p>
          <div className="social-icons">
            <a
              href="https://www.instagram.com/_.kittyshopp._/profilecard/?igsh=MXN6ZXJycmtmdTIxeA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@kittyshop223?_t=8rcJwisibCQ&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="tiktok" />
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Row: Payment and Social Icons */}
      <div className="footer-bottom-row">
        <div className="payment-icons">
          <FaCcMastercard className="payment-icon" />
          <FaApplePay className="payment-icon" />
          <FaGooglePay className="payment-icon" />
          <FaCcVisa className="payment-icon" />
        </div>
      </div>

      <p className="copyright">© 2024, KittyShop.pl</p>
    </div>
  );
};

export default Footer;
